import React from 'react'
// plugin that creates slider
import Slider from 'nouislider'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import Switch from '@material-ui/core/Switch'
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite'
import People from '@material-ui/icons/People'
import Check from '@material-ui/icons/Check'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress.js'
import Paginations from 'components/Pagination/Pagination.js'
import Badge from 'components/Badge/Badge.js'

import styles from 'assets/jss/material-kit-react/views/componentsSections/basicsStyle.js'
import ambassadorFitsumArega from 'assets/img/ambassador-fitsum-arega.png'

const useStyles = makeStyles(styles)

export default function SectionMessageFromAmbassador() {
  const classes = useStyles()
  const [checked, setChecked] = React.useState([24, 22])
  const [selectedEnabled, setSelectedEnabled] = React.useState('b')
  const [checkedA, setCheckedA] = React.useState(true)
  const [checkedB, setCheckedB] = React.useState(false)
  // React.useEffect(() => {
  //   if (
  //     !document
  //       .getElementById('sliderRegular')
  //       .classList.contains('noUi-target')
  //   ) {
  //     Slider.create(document.getElementById('sliderRegular'), {
  //       start: [40],
  //       connect: [true, false],
  //       step: 1,
  //       range: { min: 0, max: 100 },
  //     })
  //   }
  //   if (
  //     !document.getElementById('sliderDouble').classList.contains('noUi-target')
  //   ) {
  //     Slider.create(document.getElementById('sliderDouble'), {
  //       start: [20, 60],
  //       connect: [false, true, false],
  //       step: 1,
  //       range: { min: 0, max: 100 },
  //     })
  //   }
  //   return function cleanup() {}
  // })

  // const handleToggle = (value) => {
  //   const currentIndex = checked.indexOf(value)
  //   const newChecked = [...checked]

  //   if (currentIndex === -1) {
  //     newChecked.push(value)
  //   } else {
  //     newChecked.splice(currentIndex, 1)
  //   }
  //   setChecked(newChecked)
  // }
  return (
    <div className={classes.sections}>
      <div className={classes.container}>
        <GridContainer container spacing={3}>
          <GridItem xs={12} sm={12}>
            <div className={classes.title}>
              <h2>Message from the Ambasssador</h2>
            </div>

            <iframe
              width="100%"
              height="550"
              src="https://www.youtube.com/embed/77infLRGAyU"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </GridItem>

          <GridItem xs={12} sm={12}>
            <hr size="1" />
          </GridItem>

          <GridItem xs={12} sm={7}>
            <div className={classes.subtitle}>
              <h4>
                <strong>Dear Ethiopian and Ethiopian friends</strong>
              </h4>

              <h4>
                <strong>
                  The Grand Ethiopian Renaissance Dam will be a boon to Ethiopia
                  and the Horn region and beyond. GERD will more than double
                  Ethiopia’s power capacity, which will create hundreds of
                  thousands of jobs and business opportunities. We will be able
                  to generate huge foreign exchange form sale of surplus power.
                </strong>
              </h4>
            </div>
            
            <p className={classes.p}>
              Our mothers who spend time gathering firewood will be able to
              engage in major development projects, improve their health status
              and raise their children with safety and security.
            </p>
            <p className={classes.p}>
              We proudly built the GERD with our own money, with the
              contribution of all Ethiopians. Diaspora Ethiopians have long
              sought to make their contributions to the GERD but were unable to
              do so for different reasons. We have recently opened a special
              embassy account for all Ethiopians in the U.S. to deposit their
              contributions.
            </p>
            <p className={classes.p}>
              Payments can be made by direct transfer, PayPal or personal check
              payable to “Ethiopian Embassy for GERD/Abay”. We thank all donors
              in advance.
            </p>
            <p className={classes.p}>
              Let us demonstrate as a nation and people that in the face of
              adversity, we rise to the challenge of confronting the
              Coronavirus, united by our responsibility to the home we call
              Ethiopia!
            </p>
            <p className={classes.p}>Electric power to the Ethiopian people.</p>
            <p className={classes.p}>Thank you,</p>
            <p className={classes.p}>Ambassador Fitsum Arega</p>
          </GridItem>
          <GridItem xs={12} sm={5}>
            <img
              src={ambassadorFitsumArega}
              alt="Ambassador Fitsum Arega"
              style={{ width: '100%' }}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
