import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// react components for routing our app without refresh
import { Link } from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons
// core components
import Header from 'components/Header/Header.js'
import Footer from 'components/Footer/Footer.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Parallax from 'components/Parallax/Parallax.js'
// sections for this page
import HeaderLinks from 'components/Header/HeaderLinks.js'
import SectionMessageFromAmbassador from './Sections/SectionMessageFromAmbassador.js'
// import SectionBasics from './Sections/SectionBasics.js'
// import SectionNavbars from './Sections/SectionNavbars.js'
// import SectionTabs from './Sections/SectionTabs.js'
// import SectionPills from './Sections/SectionPills.js'
// import SectionNotifications from './Sections/SectionNotifications.js'
// import SectionTypography from './Sections/SectionTypography.js'
// import SectionJavascript from './Sections/SectionJavascript.js'
// import SectionCarousel from './Sections/SectionCarousel.js'
// import SectionCompletedExamples from './Sections/SectionCompletedExamples.js'
// import SectionLogin from './Sections/SectionLogin.js'
// import SectionExamples from './Sections/SectionExamples.js'
// import SectionDownload from './Sections/SectionDownload.js'

import styles from 'assets/jss/material-kit-react/views/components.js'

import paypalButton from 'assets/img/secure-donations-buttons.png'

const useStyles = makeStyles(styles)

export default function Components(props) {
  const classes = useStyles()
  const { ...rest } = props
  return (
    <div>
      <Header
        brand={
          <img src="https://ethiopianembassy.org/wp-content/uploads/2019/02/LOGO-300x46.png" />
        }
        // rightLinks={<HeaderLinks />}
        fixed
        // color="transparent"
        // changeColorOnScroll={{
        //   height: 400,
        //   color: 'white',
        // }}
        // {...rest}
        link="https://ethiopianembassy.org/"
      />
      <Parallax
        // style={{ background: '#2250B8' }}
        image={require('assets/img/gerd.jpg')}
      >
        <div className={classes.container}>
          <GridContainer container spacing={3}>
            <GridItem xs={12}>
              <div className={classes.brand}>
                <h3>&nbsp;</h3>
                <h1 className={classes.title}>GERD Fundraising</h1>
                <p className={classes.subtitle} style={{ fontWeight: 'bold' }}>
                  We proudly built the GERD with our own money, with the
                  contribution of all Ethiopians.
                </p>

                <p>&nbsp;</p>

                <p style={{ maxWidth: '100%' }}>
                  <form
                    action="https://www.paypal.com/cgi-bin/webscr"
                    method="post"
                    target="_top"
                  >
                    <input type="hidden" name="cmd" value="_donations" />
                    <input
                      type="hidden"
                      name="business"
                      value="washingtongerd@ethiopianembassy.org"
                    />
                    <input
                      type="hidden"
                      name="item_name"
                      value="GERD Fundraising"
                    />
                    <input type="hidden" name="currency_code" value="USD" />
                    <input
                      type="image"
                      src={paypalButton}
                      border="0"
                      name="submit"
                      title="PayPal - The safer, easier way to pay online!"
                      alt="Donate with PayPal button"
                      style={{ maxWidth: '100%' }}
                    />
                    <img
                      alt=""
                      border="0"
                      src="https://www.paypal.com/en_US/i/scr/pixel.gif"
                      width="1"
                      height="1"
                    />
                  </form>
                </p>
                {/* <h3 className={classes.subtitle}>
                  A Badass Material-UI Kit based on Material Design.
                </h3> */}
              </div>
            </GridItem>
            {/* <GridItem xs={12} sm={4}> */}
            {/* <div className={classes.brand}> */}
            {/* <h3 className={classes.subtitle}>
                  A Badass Material-UI Kit based on Material Design.
                </h3> */}
            {/* </div> */}
            {/* </GridItem> */}
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionMessageFromAmbassador />
        {/* <SectionBasics />
        <SectionNavbars />
        <SectionTabs />
        <SectionPills />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavascript />
        <SectionCarousel />
        <SectionCompletedExamples />
        <SectionLogin />
        <GridItem md={12} className={classes.textCenter}>
          <Link to={'/login-page'} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem>
        <SectionExamples />
        <SectionDownload /> */}
      </div>
      <Footer />
    </div>
  )
}
